import { createAsyncThunk } from '@reduxjs/toolkit'
import { apiSlice } from '../apiSlice';
import { setPdfUrlPollingLoadingStatus, setPdfUrlPollingRetryCount, setPdfUrlPollingLoadingSkeleton, setCurrentPage, setCurrentBoundingBoxes, incrementCurrentPageTrigger } from './customSlice';
import { fetchDocumentData } from '../../redux/thunks/documentData';
import { setToast } from '../../redux/slices/globalToastSlice';
import { ReloadToast } from '../../GlobalToasts/CustomToasts/ReloadToast';
import { COMPLETE_STATUS, MAX_POLLING_RETRIES, POLLING_INTERVAL } from './helpers';
import { MEDICAL_CHARGES_URL_PATH } from '../../MedicalsComponents/insights';

export const pollPdfRegenerationStatus = createAsyncThunk(
    'PdfCustomSlice/pollPdfRegenerationStatus',
    async ({ documentId }, { dispatch, getState, rejectWithValue, fulfillWithValue, requestId, signal }) => {
        try {
            dispatch(setToast({ message: 'Creating new PDF...', severity: 'info', isOpen: true }));
            const pollingFunction = async () => {
                if(!documentId) {
                    return rejectWithValue({ error: { message: 'Document ID is required' } });
                }
                dispatch(setPdfUrlPollingLoadingStatus({ [documentId]: true }));
                const pdfRegenerationStatus = await dispatch
                    (
                        apiSlice.endpoints.getPdfRegenerationStatus
                            .initiate(
                                documentId,
                                { forceRefetch: true }
                            )
                    )
                    .unwrap()

                const pollingRetriesLeft = getState().PdfCustomSlice.demandS3RegenerationPollingRetryData[documentId] - 1

                if ((pdfRegenerationStatus?.status || '').toLowerCase() !== COMPLETE_STATUS) {
                    if (pollingRetriesLeft === 0) {
                        return dispatch(setToast(ReloadToast(window)))
                    } else {
                        dispatch(setPdfUrlPollingRetryCount({ [documentId]: pollingRetriesLeft }));
                        await new Promise((resolve) => setTimeout(resolve, POLLING_INTERVAL));
                        return pollingFunction();
                    }
                } else { 
                    dispatch(setToast({ message: 'PDF created successfully', severity: 'success', isOpen: true }));
                    try {
                        await dispatch(apiSlice.endpoints.getAllFilesByDocumentId.initiate(documentId, { forceRefetch: true }));
                    }
                    catch (error) {
                        dispatch(setToast({ message: `Error fetching files: ${error.message}`, severity: 'error', isOpen: true }));
                    }
                    //then insights refetch
                    try {
                        await dispatch(apiSlice.endpoints.getEntityInsights.initiate({ documentId, insightsType: MEDICAL_CHARGES_URL_PATH }, { forceRefetch: true }));
                    }
                    catch (error) {
                        dispatch(setToast({ message: `Error fetching insights: ${error.message}`, severity: 'error', isOpen: true }));
                    }
                    //finally fetch the pdf url
                    const pdfUrl = await dispatch(apiSlice.endpoints.getPdfUrl.initiate(documentId, { forceRefetch: true })).unwrap();
                    //reset polling retry count
                    dispatch(setPdfUrlPollingRetryCount({ [documentId]: MAX_POLLING_RETRIES }));
                    //call fetch document data to update the document data query
                    const user = getState().User.user;
                    //fetch the document data one more time after the regeneration is complete. update RTK implementation should avoid a lot of weird edge cases
                    //this fetch call is independent of the getDocumentDataQuery invalidation which triggers the polling and all other refetches
                    dispatch(fetchDocumentData( { documentId, user }));
                    //try to merge the refetched data to the form values of any open forms and rehydrate the form
                    //fulfill the promise with the pdf url 'Complete' status
                    return fulfillWithValue(COMPLETE_STATUS);
                }
            }
            if (!(getState().PdfCustomSlice.demandS3RegenerationLoadingData[documentId])) {
                dispatch(setPdfUrlPollingLoadingSkeleton({ [documentId]: true }));
                dispatch(setPdfUrlPollingLoadingStatus({ [documentId]: true }));

                await new Promise((resolve) => setTimeout(resolve, 5000));
                return pollingFunction();
            } else {
                dispatch(setPdfUrlPollingRetryCount({ [documentId]: MAX_POLLING_RETRIES }));
            }
        } catch (error) {
            dispatch(setToast({ message: `Error polling for pdf regeneration: ${error.message}`, severity: 'error', isOpen: true }));
            return rejectWithValue(error);
        }
    }
);

export const viewPageInDocument = (pageNumber, boundingBoxes) => (dispatch) => {
    dispatch(setCurrentPage(pageNumber));
    dispatch(setCurrentBoundingBoxes(boundingBoxes));
    dispatch(incrementCurrentPageTrigger());
};

