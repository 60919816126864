import React, { useCallback, useState } from 'react';
import { Typography, Collapse, Stack } from '@mui/material';
import ExpandMore from '../../CommonComponents/ExpandMore';
import AiReferencesTreatmentDetailPanel from './AiReferencesTreatmentDetailPanel';
import DataGridWithExpandedDetails from '../../CommonComponents/DataGrid/DataGridWithExpandedDetails';
import { formatPageNumbers, formatDateRange } from '../DemandForms/Utilities';

const aggregateReferenceData = (data) => {
  const dataAggregatedByProvider = {};
  for (const treatment of data) {
    console.log(treatment);
    const provider = dataAggregatedByProvider[treatment.provider_organization.value];
    if (provider) {
      provider.pages = [...provider.pages, ...treatment.generated_page_number_list];
      provider.dates.push(treatment.treatment_date.value);
      provider.treatments.push({ ...treatment });
    } else {
      dataAggregatedByProvider[treatment.provider_organization.value] = {
        facility: treatment.provider_organization.value,
        pages: [...treatment.generated_page_number_list],
        dates: [treatment.treatment_date.value],
        treatments: [{ ...treatment }],
      };
    }
  };
  return dataAggregatedByProvider;
};

const AiReferenceTable = ({documentId, tableData}) => {
  const [expandedState, setExpandedState] = useState(false);

  const handleExpandClick = () => {
    setExpandedState(!expandedState);
  };
  console.log(tableData);
  const rows = Object.values(aggregateReferenceData(tableData)).map((row, index) => ({ ...row, id: index + 1 }));
  console.log(rows);

  const columns = [
    {
      field: 'facility',
      headerName: 'Facility',
      width: 200,
      minWidth: 125,
      pinnable: false,
      editable: false,
      renderCell: ({ row }) => {
        return <Typography variant="tableP1">{row.facility}</Typography>;
      },
    },
    {
      field: 'pages',
      headerName: 'Pages',
      width: 150,
      pinnable: false,
      editable: false,
      renderCell: ({ row }) => {
        return <Typography variant="tableP1">{formatPageNumbers(row.pages)}</Typography>;
      },
    },
    {
      field: 'dates',
      headerName: 'Dates',
      width: 300,
      pinnable: false,
      editable: false,
      filterable: true,
      renderCell: ({ row }) => {
        return <Typography variant="tableP1">{formatDateRange(row.dates)}</Typography>;
      },
    },
  ];

  const getDetailPanelContent = useCallback(
    ({ row }) => <AiReferencesTreatmentDetailPanel documentId={documentId} treatments={row.treatments} />,
    []
  );

  return (
    <>
      <Stack direction="row" alignItems="center">
        <Typography variant="body1">AI References</Typography>
        <ExpandMore expand={expandedState} onClick={handleExpandClick} size="small" />
      </Stack>
      <Collapse in={expandedState}>
        <DataGridWithExpandedDetails rows={rows} columns={columns} getDetailPanelContent={getDetailPanelContent} />
      </Collapse>
    </>
  );
};

export default AiReferenceTable;
