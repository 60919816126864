import React, { useCallback, useState } from 'react';
import { Box } from '@mui/material';
import { DataGridPro, GRID_DETAIL_PANEL_TOGGLE_COL_DEF } from '@mui/x-data-grid-pro';
import { KeyboardArrowDownOutlined, KeyboardArrowUpOutlined } from '@mui/icons-material';
import { grey } from '@mui/material/colors';
import TableNoRowsOverlay from '../../MedicalsComponents/TableNoRowsOverlay';

const slots = {
  detailPanelExpandIcon: KeyboardArrowDownOutlined,
  detailPanelCollapseIcon: KeyboardArrowUpOutlined,
  noRowsOverlay: TableNoRowsOverlay,
};

const dataGridStyles = { backgroundColor: '#FFFFFF' };

const DataGridWithExpandedDetails = ({
  rows,
  columns,
  getDetailPanelContent,
  isDetailPanel = false,
  ...dataGridProps
}) => {
  const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = useState([]);

  const columnsWithDetailToggle = [
    {
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
    },
    ...columns,
  ];

  const useAutoHeight = rows.length < 7;

  const getRowHeight = useCallback(() => 43, []);

  const getDetailPanelHeight = useCallback(() => 'auto', []);

  const handleDetailPanelExpandedRowIdsChange = useCallback((newIds) => {
    setDetailPanelExpandedRowIds(newIds?.length > 1 ? [newIds[newIds?.length - 1]] : newIds);
  }, []);

  const getRowClassName = useCallback((params) => {
    const evenOrOddClassName = params.indexRelativeToCurrentPage % 2 === 0 ? 'doc-row-even' : 'doc-row-odd';

    return evenOrOddClassName;
  }, []);

  return (
    <Box
      sx={{
        height: useAutoHeight ? '' : '400px',
        ...(isDetailPanel && { p: 2, backgroundColor: grey[100], borderBottom: '1px solid', borderColor: 'divider' }),
      }}
    >
      <DataGridPro
        rows={rows}
        columns={columnsWithDetailToggle}
        getRowClassName={getRowClassName}
        getRowHeight={getRowHeight}
        getDetailPanelContent={getDetailPanelContent}
        getDetailPanelHeight={getDetailPanelHeight}
        detailPanelExpandedRowIds={detailPanelExpandedRowIds}
        onDetailPanelExpandedRowIdsChange={handleDetailPanelExpandedRowIdsChange}
        autoHeight={useAutoHeight}
        disableRowSelectionOnClick
        disableColumnReorder
        disableColumnSelector
        slots={slots}
        sx={dataGridStyles}
        {...(isDetailPanel && { density: 'compact' })}
        {...dataGridProps}
      />
    </Box>
  );
};

export default DataGridWithExpandedDetails;
