import awsExports from './aws-exports';

const rootApiUrl = awsExports.ROOT_API_URL;

export const getUserApi = async (user) => {

    if (!user)
        return null;

    const settings = {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            Authorization: user.signInUserSession.accessToken.jwtToken
        }
    };

    const response = await fetch(
        rootApiUrl + "/user/" + user.username, settings
    ).then((response) => response.json())
        .finally(() => {
        });

    return response;
};

export const getCustomerApi = async (customerId, user) => {
    if (!customerId) {
        return null;
    };

    const settings = {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            Authorization: user.signInUserSession.accessToken.jwtToken
        },
    };

    const response = await fetch(
        rootApiUrl + `/customer/${customerId}`, settings
    ).then((response) => response.json());

    return response;
};

export const getDocumentApi = async (documentId, user) => {

    if (!user || !documentId)
        return null;

    const settings = {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            Authorization: user.signInUserSession.accessToken.jwtToken
        }
    };

    const response = await fetch(
        rootApiUrl + "/document/" + documentId, settings
    ).then((response) => response);

    return response;
};

export const createDocumentApi = async (document, user) => {
    const settings = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            Authorization: user.signInUserSession.accessToken.jwtToken
        },
        body: JSON.stringify(document)
    };

    const response = await fetch(rootApiUrl + "/document", settings)
        .then((response) => response)

    return response;
};

export const postDocumentFilesApi = async (documentId, files, user) => {

    if (!user || !documentId)
        return null;

    const settings = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            Authorization: user.signInUserSession.accessToken.jwtToken
        },
        body: JSON.stringify(files)
    };

    const response = await fetch(
        rootApiUrl + "/document/" + documentId + "/files", settings
    ).then((response) => response);

    return response;
}

export const postDocumentFilesByIdsApi = async (documentId, updatedFilesList, user) => {
    const settings = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            Authorization: user.signInUserSession.accessToken.jwtToken
        },
        body: JSON.stringify(updatedFilesList)
    };

    return fetch(rootApiUrl + "/document/" + documentId + "/files/fileEntityData", settings)
        .then((response) => response)
        .catch((error) => error)
}


export const sendPreviewApi = async (documentId, user) => {

    const settings = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            Authorization: user.signInUserSession.accessToken.jwtToken
        }
    };

    const response = await fetch(
        rootApiUrl + "/document/" + documentId + "/sendPreview", settings
    ).then((response) => { return response; })
        .finally(() => {
        });

    return response;
};

export const setDemandNotesApi = async (documentId, user, noteContent) => {
    const settings = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            Authorization: user.signInUserSession.accessToken.jwtToken
        },
        body: JSON.stringify(noteContent)
    };

    const response = await fetch(
        rootApiUrl + "/document/" + documentId + "/setDemandNotes", settings
    ).then((response) => { return response; })
        .finally(() => {
        });

    return response;
};

export const sendMedicalExtractionApi = async (documentId, user) => {

    const settings = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            Authorization: user.signInUserSession.accessToken.jwtToken
        }
    };

    const response = await fetch(
        rootApiUrl + "/document/" + documentId + "/sendMedicalExtraction", settings
    ).then((response) => { return response; })
        .finally(() => {
        });

    return response;
};


export const sendToCarrierApi = async (documentId, user) => {

    const settings = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            Authorization: user.signInUserSession.accessToken.jwtToken
        }
    };

    const response = await fetch(
        rootApiUrl + "/document/" + documentId + "/sendCarrier", settings
    ).then((response) => response)
        .finally(() => {
        });

    return response;
};

export const faxToCarrierApi = async (documentId, user) => {

    const settings = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            Authorization: user.signInUserSession.accessToken.jwtToken
        }
    };

    const response = await fetch(
        rootApiUrl + "/document/" + documentId + "/sendFax", settings
    ).then((response) => { return response; })
        .finally(() => {
        });

    return response;
};

export const sendManuallyApi = async (documentId, formData, user) => {

    const settings = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            Authorization: user.signInUserSession.accessToken.jwtToken
        },
        body: JSON.stringify(formData)
    };

    const response = await fetch(
        rootApiUrl + "/document/" + documentId + "/sendManually", settings
    ).then((response) => { return response; })
        .finally(() => {
        });

    return response;
};

export const archiveApi = async (documentId, formData, user) => {

    const settings = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            Authorization: user.signInUserSession.accessToken.jwtToken
        },
        body: JSON.stringify(formData)
    };

    const response = await fetch(rootApiUrl + "/document/" + documentId + "/archive", settings)
        .then((response) => { return response; })
    return response;
};

export const submitFirmApprovalRequestApi = async (documentId, user) => {

    const settings = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            Authorization: user.signInUserSession.accessToken.jwtToken
        }
    };

    const response = await fetch(
        rootApiUrl + "/document/" + documentId + "/submitforfirmapproval", settings
    ).then((response) => { return response; })
        .finally(() => {
        });

    return response;
};

export const rejectDemandApi = async (documentId, formData, user) => {

    const settings = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            Authorization: user.signInUserSession.accessToken.jwtToken
        },
        body: JSON.stringify(formData)
    };

    const response = await fetch(
        rootApiUrl + "/document/" + documentId + "/reject", settings
    ).then((response) => { return response; })
        .finally(() => {
        });

    return response;
};

export const approveFirmDemandApi = async (documentId, user) => {

    const settings = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            Authorization: user.signInUserSession.accessToken.jwtToken
        }
    };

    const response = await fetch(
        rootApiUrl + "/document/" + documentId + "/firmapproval", settings
    ).then((response) => { return response; })
        .finally(() => {
        });

    return response;
};

export const addContactLogApi = async (contactLog, user) => {

    const settings = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            Authorization: user.signInUserSession.accessToken.jwtToken,
        },
        body: JSON.stringify(contactLog)
    };

    const response = await fetch(
        rootApiUrl + "/document/" + contactLog['documentId'] + "/contactlog", settings
    ).then((response) => { return response; })
        .finally(() => {
        });

    return response;
};

export const getContactLogApi = async (documentId, user) => {

    const settings = {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            Authorization: user.signInUserSession.accessToken.jwtToken
        }
    };

    const response = await fetch(
        rootApiUrl + "/document/" + documentId + "/contactlog", settings
    ).then((response) => { return response; })
        .finally(() => {
        });

    return response;
};

export const getConfigValue = async (configKey, customerId, user) => {

    const payload = { configKey: configKey };
    if (customerId) {
        payload['customerId'] = customerId;
    }

    const settings = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            Authorization: user.signInUserSession.accessToken.jwtToken
        },
        body: JSON.stringify(payload)
    };

    const response = await fetch(
        rootApiUrl + "/getConfigValue", settings
    ).then((response) => response.json())
        .finally(() => {
        });

    return response["configValue"];
};

export const pushDataToS3 = async (uploadFields, file) => {
    const headers = new Headers({});
    const formData = new FormData();
    formData.append("key", uploadFields["fields"]["key"]);
    formData.append("AWSAccessKeyId", uploadFields["fields"]["AWSAccessKeyId"]);
    formData.append("policy", uploadFields["fields"]["policy"]);
    formData.append("signature", uploadFields["fields"]["signature"]);
    formData.append("x-amz-security-token", uploadFields["fields"]["x-amz-security-token"]);
    formData.append("file", file);

    const response = await fetch(uploadFields["url"], {
        method: 'POST',
        headers: headers,
        body: formData
    });

    return response.status === 204;
};

export const getUploadMedicalsUrl = async (documentId, batchId, fileExtension, user) => {
    const settings = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            Authorization: user.signInUserSession.accessToken.jwtToken
        },
        body: JSON.stringify({ documentId, batchId, fileExtension })
    };

    const response = await fetch(rootApiUrl + "/document/generateUploadMedicalsUrl", settings)
        .then((response) => { return response; })

    return response;
};

export const uploadMedicalsApi = async (documentId, batchId, fileExtension, user) => {
    const settings = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            Authorization: user.signInUserSession.accessToken.jwtToken
        },
        body: JSON.stringify({ documentId, batchId, fileExtension })
    };

    const response = await fetch(rootApiUrl + "/document/uploadMedicals", settings)
        .then(response => response)

    return response;
};

export const postToAuditLog = async (user, documentId, auditType, extraEventData) => {
    const settings = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            Authorization: user.signInUserSession.accessToken.jwtToken,
        },
        body: JSON.stringify({ documentId: documentId, auditType: auditType, extraEventData: extraEventData })
    };

    const response = await fetch(
        rootApiUrl + "/document/" + documentId + "/audit", settings
    ).then((response) => { return response; })
        .finally(() => {
        });
    return response;
}

export const searchCarriersApi = async (user, searchText) => {
    const settings = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            Authorization: user.signInUserSession.accessToken.jwtToken
        },
        body: JSON.stringify({ searchStr: searchText || "" })
    };

    const response = await fetch(rootApiUrl + "/carriers", settings)
        .then((response) => response)
    return response;

};

export const getUsersApi = async (user, customerId) => {
    const settings = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            Authorization: user.signInUserSession.accessToken.jwtToken
        },
        body: JSON.stringify({ customerId: customerId })
    };

    const response = await fetch(rootApiUrl + "/users", settings)
        .then((response) => response)
    return response;
};

export const getDocumentModifiedPdf = async (documentId, user) => {
    const settings = {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            Authorization: user.signInUserSession.accessToken.jwtToken
        }
    };

    return await fetch(rootApiUrl + "/document/" + documentId + "/modifiedPdf", settings)
        .then((response) => response)
};

export const saveDocumentModifiedPdf = async (documentId, file, user) => {
    const settings = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            Authorization: user.signInUserSession.accessToken.jwtToken
        },
    };

    const responseJson = await fetch(rootApiUrl + "/document/" + documentId + "/modifiedPdf", settings)
        .then((response) => response.json())

    const uploadUrl = responseJson["uploadFields"]["url"];
    const uploadFields = responseJson["uploadFields"]["fields"];
    const headers = new Headers({});
    const formData = new FormData();
    formData.append("key", uploadFields["key"]);
    formData.append("AWSAccessKeyId", uploadFields["AWSAccessKeyId"]);
    formData.append("policy", uploadFields["policy"]);
    formData.append("signature", uploadFields["signature"]);
    formData.append("x-amz-security-token", uploadFields["x-amz-security-token"]);
    formData.append("file", file);

    const uploadResponse = await fetch(uploadUrl, {
        method: 'POST',
        headers: headers,
        body: formData
    });
    return uploadResponse.status == 204;
};

//get demandS3Url for individual file
export const getFileUrl = async (fileId, user) => {
    const settings = {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            Authorization: user.signInUserSession.accessToken.jwtToken
        }
    };

    const response = await fetch(rootApiUrl + "/file/" + fileId + "/generateDownloadUrl", settings)
        .then((response) => response)

    return response;
};

export const setFilesSequenceApi = async (documentId, orderedFiles, user) => {
    const settings = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            Authorization: user.signInUserSession.accessToken.jwtToken
        },
        body: JSON.stringify(orderedFiles)
    };

    const response = await fetch(rootApiUrl + "/document/" + documentId + "/setFilesSequence", settings)
        .then((response) => response)

    return response;
}

export const getUploadUrl = async (fileName, documentId, user) => {
    const settings = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            Authorization: user.signInUserSession.accessToken.jwtToken
        },
        body: JSON.stringify({ fileName: fileName, documentId: documentId })
    };

    const response = await fetch(rootApiUrl + "/document/generateUploadUrl", settings)
        .then((response) => response.json())
        .catch((error) => 'Error getting upload url')
    return response["uploadFields"];
};

export const regenerateDocumentApi = async (documentId, user) => {

    const settings = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            Authorization: user.signInUserSession.accessToken.jwtToken
        }
    };

    const response = await fetch(rootApiUrl + "/document/" + documentId + "/documentRegenerate", settings)
        .then((response) => response.json())

    return response;
};

export const regenerateDocumentStatusApi = async (documentId, user) => {

    const settings = {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            Authorization: user.signInUserSession.accessToken.jwtToken
        }
    };

    return await fetch(rootApiUrl + "/document/" + documentId + "/documentRegenerate", settings)
        .then((response) => response.json())

};

export const handleUpdateFilePdf = async ({fileId, fileName, user, pageNumbers}) => {
    const settings = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            Authorization: user.signInUserSession.accessToken.jwtToken
        },
        body: JSON.stringify({ fileName, ...(pageNumbers && { pageNumbers })}) // would exclude if pageNumbers was [] but we don't allow deleting all pages
    };

    const response = await fetch(rootApiUrl + "/file/" + fileId, settings)
        .then((response) => response.json())

    return response;
}

export const bumpTemplatePinforDocument = async (documentId, user) => {

    const settings = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            Authorization: user.signInUserSession.accessToken.jwtToken
        },
    };
    return await fetch(`${rootApiUrl}/document/${documentId}/bumpTemplatePin`, settings)
        .then((response) => response);
}

export const deleteFile = async (fileId, user) => {

    const settings = {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            Authorization: user.signInUserSession.accessToken.jwtToken
        },
    };

    const response = await fetch(rootApiUrl + "/file/" + fileId, settings)
        .then((response) => response)

    return response;
}

export const getDocumentBatches = async (documentId, user) => {

    const settings = {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            Authorization: user.signInUserSession.accessToken.jwtToken
        },
    }

    const response = await fetch(rootApiUrl + "/document/" + documentId + "/batches", settings)
        .then((response) => response.json());

    return response;
}

export const reloadMedicals = async (documentId, batchId, user) => {

    const settings = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            Authorization: user.signInUserSession.accessToken.jwtToken
        },
    }

    const response = await fetch(`${rootApiUrl}/document/${documentId}/batch/${batchId}/reloadQaMedicals`, settings)
        .then((response) => response);

    return response;
}

// this will return ALL templates, regardless of live status
export const getAvailableTemplatesForCustomer = async (user, targetCustomer = null) => {
    const settings = {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            Authorization: user.signInUserSession.accessToken.jwtToken
        },
    }

    const url = targetCustomer ? `${rootApiUrl}/templates?onBehalfOf=${targetCustomer}` : `${rootApiUrl}/templates`;
    const response = await fetch(url, settings)
        .then((response) => response.json());

    return response;
}

// this will only return templates which have Live versions available
export const getAvailableLiveTemplatesForCustomer = async (user, targetCustomer = null) => {
    const settings = {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            Authorization: user.signInUserSession.accessToken.jwtToken
        },
    }

    const url = targetCustomer ? `${rootApiUrl}/templates?live=true&onBehalfOf=${targetCustomer}` : `${rootApiUrl}/templates?live=true`;
    const response = await fetch(url, settings)
        .then((response) => response.json());

    return response;
}

// this archives ("deletes") a template
export const archiveTemplate = async (templateId, user) => {
    const settings = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            Authorization: user.signInUserSession.accessToken.jwtToken
        },
    }

    const response = await fetch(`${rootApiUrl}/template/${templateId}/archive`, settings)
        .then((response) => response);
    return response;
}

// this reverses the archive action
export const restoreTemplate = async (templateId, user) => {
    const settings = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            Authorization: user.signInUserSession.accessToken.jwtToken
        },
    }

    const response = await fetch(`${rootApiUrl}/template/${templateId}/restore`, settings)
        .then((response) => response);
    return response;
}

// this endpoint will publish the specified version of a template, meaning it can take an earlier template and make it the live (and thus top) version
export const publishEarlierTemplateVersion = async (templateId, version, user) => {
    const settings = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            Authorization: user.signInUserSession.accessToken.jwtToken
        },
        body: JSON.stringify({ version: version })
    }

    const response = await fetch(`${rootApiUrl}/template/${templateId}/publishEarlierVersion`, settings)
        .then((response) => response);
    return response;
}


// this endpoint will fetch ALL versions of a template and return them in a list
export const getTemplateHistory = async (templateId, user) => {
    const settings = {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            Authorization: user.signInUserSession.accessToken.jwtToken
        },
    }

    const response = await fetch(`${rootApiUrl}/template/${templateId}/history`, settings)
        .then((response) => response.json());

    return response;
}

// this endpoint will fetch the content of the template@version attached to the document passed in
export const getTemplateContentFromDocumentId = async (documentId, user) => {
    const settings = {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            Authorization: user.signInUserSession.accessToken.jwtToken
        },
    }

    const response = await fetch(`${rootApiUrl}/document/${documentId}/getTemplateContent`, settings)
        .then((response) => response.json());

    return response;
}

export const generateTemplatePreviewApi = async (templateText, user, overrideJsonBlobText = '', documentId = '') => {
    const lambdaRequestBody = {
        template_content: templateText,
        testing: true,
        testingWithAuxiliaryData: true,
        overrideJsonContent: JSON.parse(overrideJsonBlobText),
        document_id: documentId
    };

    const settings = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            Authorization: user.signInUserSession.accessToken.jwtToken
        },
        body: JSON.stringify(lambdaRequestBody)
    };


    return fetch(rootApiUrl + "/generateTestDemandPdf", settings)
        .then(response => response.json())
        .catch(err => err);
}

// this endpoint will take the most recent version of the specified template and make it live (assuming it is not already)
export const makeLatestTemplateVersionLive = async (templateId, user) => {
    const settings = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            Authorization: user.signInUserSession.accessToken.jwtToken
        },
    }

    const response = await fetch(`${rootApiUrl}/template/${templateId}/live`, settings)
        .then((response) => response);
    return response;
}


export const getLiveTemplateContentFromTemplateId = async (templateId, user) => {
    const settings = {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            Authorization: user.signInUserSession.accessToken.jwtToken
        },
    }

    const url = `${rootApiUrl}/template/${templateId}/getTemplateContent?live=true`;
    const response = await fetch(url, settings)
        .then((response) => response.json());

    return response;
}

export const getLatestTemplateContentFromTemplateId = async (templateId, user) => {
    const settings = {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            Authorization: user.signInUserSession.accessToken.jwtToken
        },
    }

    const url = `${rootApiUrl}/template/${templateId}/getTemplateContent`;
    const response = await fetch(url, settings)
        .then((response) => response.json());

    return response;
}

// get template content for specific version
export const getTemplateContentFromTemplateIdByVersion = async (templateId, version, user) => {
    const settings = {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            Authorization: user.signInUserSession.accessToken.jwtToken
        },
    }

    const response = await fetch(`${rootApiUrl}/template/${templateId}/getTemplateContent?version=${version}`, settings)
        .then((response) => response.json());

    return response;
}

export const postTemplate = async (payload, user, targetCustomer = null) => {
    const settings = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            Authorization: user.signInUserSession.accessToken.jwtToken
        },
        body: JSON.stringify(payload)
    }

    const url = targetCustomer ? `${rootApiUrl}/template?onBehalfOf=${targetCustomer}` : `${rootApiUrl}/template`;
    const response = await fetch(url, settings)
        .then((response) => response)
        .catch((error) => error);

    return response;
}

export const getLatestTemplateVersionById = async (templateId, user) => {
    const settings = {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            Authorization: user.signInUserSession.accessToken.jwtToken
        },
    }

    const url = `${rootApiUrl}/template/${templateId}`;
    const response = await fetch(url, settings)
        .then((response) => response.json());

    return response;
}

export const getLiveTemplateById = async (templateId, user) => {
    const settings = {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            Authorization: user.signInUserSession.accessToken.jwtToken
        },
    }

    const url = `${rootApiUrl}/template/${templateId}?live=true`;
    const response = await fetch(url, settings)
        .then((response) => response.json());

    return response;
}

//get template ID for specific version
export const getTemplateIdByVersion = async (templateId, version, user) => {
    const settings = {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            Authorization: user.signInUserSession.accessToken.jwtToken
        },
    }

    const response = await fetch(`${rootApiUrl}/template/${templateId}?version=${version}`, settings)
        .then((response) => response.json());

    return response;
}

export const getAvailableTemplatesAndCustomFieldsForCustomer = async (user, targetCustomer = null) => {
    const settings = {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            Authorization: user.signInUserSession.accessToken.jwtToken
        },
    }

    const url = targetCustomer ? `${rootApiUrl}/templates/getCustomFields?onBehalfOf=${targetCustomer}` : `${rootApiUrl}/templates/getCustomFields`;
    const response = await fetch(url, settings)
        .then((response) => response.json());

    return response;
}

export const getDocumentUsageForTemplate = async (templateId, user) => {
    const settings = {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            Authorization: user.signInUserSession.accessToken.jwtToken
        },
    }

    const response = await fetch(`${rootApiUrl}/template/${templateId}/getDocumentUsage`, settings)
        .then((response) => response.json());

    return response;
}

export const getIcdCodesAutocomplete = async (user, userInput) => {
    const settings = {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            Authorization: user.signInUserSession.accessToken.jwtToken
        },
    }

    const response = await fetch(`${rootApiUrl}/icdCodes/autocomplete/${userInput}`, settings)
        .then((response) => response.json());

    return response;
}

export const getUsersListApi = (customerId, user) => {
    const settings = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            Authorization: user.signInUserSession.accessToken.jwtToken
        },
        body: JSON.stringify({ customerId: customerId })
    };

    return fetch(rootApiUrl + "/users", settings)
        .then((response) => response.json());
}

export const generateNarrativeForField = async (fieldName, documentId, user) => {
    const settings = {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            Authorization: user.signInUserSession.accessToken.jwtToken
        },
    };

    const statusCodesToRetry = [202, 500, 503];
    let numRetries = 20;

    while (true) {
        const result = await fetch(`${rootApiUrl}/document/${documentId}/generateNarrative/${fieldName}`, settings)
            .then(async (response) => {
                const responseObject = await response.json();
                return {status: response.status, body: responseObject}
            });
        if (numRetries === 0 || !statusCodesToRetry.includes(result.status)) {
            return result;
        }
        numRetries -= 1;
        console.log(`Retrying on ${result.status} status code, retries left: ${numRetries}`)
    }
}