import { createSlice } from '@reduxjs/toolkit';
import { pollPdfRegenerationStatus } from './thunks';
import { updateLoadingState } from './helpers';

const initialPageAndBoundingBoxState = {
    currentPage: 0,
    currentBoundingBoxes: [],
    currentPageTrigger: 0,
};

const initialState = {
    demandS3RegenerationLoadingData: {},
    demandS3RegenerationPollingRetryData: {},
    demandS3RegenerationSkeletonData: {},
    ...initialPageAndBoundingBoxState,
};

export const PdfCustomSlice = createSlice({
    name: 'PdfCustomSlice',
    initialState,
    reducers: {
        setPdfUrlPollingLoadingStatus: (state, action) => {
            state.demandS3RegenerationLoadingData = {
                ...state.demandS3RegenerationLoadingData,
                ...action.payload
            }
        },
        setPdfUrlPollingRetryCount: (state, action) => {
            state.demandS3RegenerationPollingRetryData = {
                ...state.demandS3RegenerationPollingRetryData,
                ...action.payload
            }
        },
        setPdfUrlPollingLoadingSkeleton: (state, action) => {
            state.demandS3RegenerationSkeletonData = {
                ...state.demandS3RegenerationSkeletonData,
                ...action.payload
            }
        },
        setCurrentPage: (state, action) => {
            state.currentPage = action.payload;
        },
        setCurrentBoundingBoxes: (state, action) => {
            state.currentBoundingBoxes = action.payload;
        },
        incrementCurrentPageTrigger: (state) => {
            state.currentPageTrigger = state.currentPageTrigger + 1;
        },
        resetPageAndBoundingBoxes: (state) => {
            return {
                ...state,
                ...initialPageAndBoundingBoxState,
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(pollPdfRegenerationStatus.fulfilled, (state, action) => {
                action.payload === 'complete' && updateLoadingState(state, action, false)
            })
        }
    })

export const {
    setPdfUrlPollingLoadingStatus,
    setPdfUrlPollingRetryCount,
    setPdfUrlPollingLoadingSkeleton,
    setCurrentPage,
    setCurrentBoundingBoxes,
    incrementCurrentPageTrigger,
    resetPageAndBoundingBoxes,
} = PdfCustomSlice.actions;

export default PdfCustomSlice.reducer;