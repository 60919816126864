import React, { useState } from 'react';
import { EditOutlined } from '@mui/icons-material';
import { Card, Collapse, IconButton, Stack } from '@mui/material';
import Title from './Title';
import ExpandMore from './ExpandMore';
import { setDemandEditOpen } from '../redux/slices/demandDomSlice';
import { useDispatch } from 'react-redux';

const CollapseCard = ({ title, adminView, children, expanded }) => {
  const [expandedState, setExpandedState] = useState(expanded);
  const dispatch = useDispatch();

  const handleExpandClick = () => {
    setExpandedState(!expandedState);
  };

  return (
    <Card>
      <Stack
        direction="row"
        justifyContent={'space-between'}
        alignItems={'center'}
        onClick={handleExpandClick}
        sx={{
          cursor: 'pointer',
        }}
      >
        <Title>{title}</Title>
        <Stack direction="row" spacing={1}>
          {adminView && (
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                dispatch(setDemandEditOpen(true));
              }}
            >
              <EditOutlined />
            </IconButton>
          )}
          <ExpandMore expand={expandedState} onClick={handleExpandClick} />
        </Stack>
      </Stack>
      <Collapse in={expandedState} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    </Card>
  );
};

export default CollapseCard;
