import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Typography } from '@mui/material';
import DataGridWithExpandedDetails from '../../CommonComponents/DataGrid/DataGridWithExpandedDetails';
import { useGetAllFilesByDocumentIdQuery } from '../../services/documentFiles/documentFilesApi';
import { viewPageInDocument } from '../../services/pdf/thunks';
import { getDocumentFileByPageNumber } from '../../common-document';

const initialState = {
  sorting: {
    sortModel: [{ field: 'page', sort: 'asc' }],
  },
};

const AiReferencesPageDetailPanel = ({ pages }) => {
  const dispatch = useDispatch();

  const { documentId } = useParams();

  const { data: documentFiles = [] } = useGetAllFilesByDocumentIdQuery(documentId);

  const getDocumentName = (pageNumber) => {
    const file = getDocumentFileByPageNumber(documentFiles, pageNumber);
    return file?.fileEntityData?.fileName ?? '';
  };
  const getDocumentData = (fileEntityId) => {
    const file = documentFiles.find((file) => file.fileEntityId === fileEntityId);
    return file ? file?.fileEntityData : {};
  };

  const rows = pages.map((page, index) => ({ page: page, id: index + 1 }));

  const columns = [
    {
      field: 'page',
      headerName: 'Pages',
      width: 150,
      editable: false,
      filterable: true,
      renderCell: ({ row }) => {
        return <Typography variant="tableP1">{row.page}</Typography>;
      },
    },
    {
      field: 'document',
      headerName: 'Document',
      width: 400,
      editable: false,
      filterable: true,
      valueGetter: (params) => getDocumentName(params.row.page),
      renderCell: ({ row }) => {
        const documentName = getDocumentName(row.page);

        return (
          <Typography
            variant="tableP1"
            color="secondary"
            onClick={() => dispatch(viewPageInDocument(row.page, row?.bboxes))}
            sx={{
              cursor: 'pointer',
            }}
          >
            {documentName}
          </Typography>
        );
      },
    },
  ];

  return <DataGridWithExpandedDetails rows={rows} columns={columns} isDetailPanel initialState={initialState} />;
};

export default AiReferencesPageDetailPanel;
