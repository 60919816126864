import React, { useState } from 'react';
import {
    Button,
    DialogContent,
    DialogActions,
    TextField,
    Typography,
    Grid,
    DialogTitle,
    Stack,
    Checkbox,
    Box
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setEditDocumentNames } from '../redux/slices/globalDialogueSlice';
import { isDemandOrCoverLetter } from "../common-document";
import { saveDocumentFilesBydId } from '../redux/thunks/demandData';
import { useGetAllFilesByDocumentIdQuery } from "../services/documentFiles/documentFilesApi";

const EditDocumentNames = () => {
    const dispatch = useDispatch();

    const { open, data } = useSelector(state => state.GlobalDialogues.editDocumentNames);
    const { documentId, user } = data;

    const { data: documentFiles = [], isLoading: documentFilesLoading } = useGetAllFilesByDocumentIdQuery(documentId, {
        refetchOnMountOrArgChange: true,
      });

    const documentData = documentFiles.map((file) => {
        return {
            id: file.fileEntityId,
            fileName: file?.fileEntityData?.fileName,
            detectedType: file?.fileEntityData?.detectedType || '',
            aliasFileName: file?.fileEntityData?.aliasFileName,
            defaultValue: file?.fileEntityData?.aliasFileName || file?.fileEntityData?.fileName,
            hasDividerPage: Boolean(file?.fileEntityData?.dividerPageId),
            originalDividerPageSetting: Boolean(file?.fileEntityData?.dividerPageId),
        }
    });

    const [formState, setFormState] = useState(documentData.map((file) => {
        return {
            ...file,
            currentValue: file.defaultValue
        }
    }));

    const anyErrors = formState.some((file) => !file.currentValue);
    const allChanged = formState.filter((file) => file.defaultValue !== file.currentValue || file.hasDividerPage !== file.originalDividerPageSetting);
    const anyChange = allChanged.length > 0;

    const handleClose = () => {
        dispatch(setEditDocumentNames({ open: false, data: {} }));
    };

    const handleSave = () => {
        const updatedFiles = allChanged.map((file) => {
            return {
                id: file.id,
                aliasFileName: file.currentValue,
                hasDividerPage: file.hasDividerPage // Include updated divider page info
            }
        });

        dispatch(saveDocumentFilesBydId({ documentId, files: updatedFiles, user }));
        handleClose();
    }

    return (
        <>
            <DialogTitle>
                Edit document display names
            </DialogTitle>
            <DialogContent>

                <Grid container spacing={2}>

                    <Grid item xs={12}>
                        <Stack
                            direction="row"
                            alignItems={"center"}
                        >
                            <Box
                                sx={{
                                    width: '45%'
                                }}
                            >
                                <Typography
                                    variant="body2"
                                    align="left"
                                    color={'text.secondary'}
                                >
                                    File names
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    width: '45%'
                                }}
                            >
                                <Typography
                                    variant="body2"
                                    align="left"
                                    color={'text.secondary'}
                                >
                                    Display names
                                </Typography>
                            </Box>
                            <Box sx={{ width: '10%' }}>
                                <Typography variant="body2" align="left" color={'text.secondary'}>Has divider page</Typography>
                            </Box>
                        </Stack>
                        {documentData.map((file, idx) => {
                            const hasChanged = (file.defaultValue !== formState[idx].currentValue || file.hasDividerPage !== formState[idx].hasDividerPage);
                            const hasNoAlias = !file.aliasFileName && !hasChanged;
                            const currentValue = formState[idx].currentValue;
                            const aliasMatchesFileName = currentValue === file.fileName;
                            const helperText = () => {
                                if (!currentValue) {
                                    return "Required";
                                } else if (hasChanged) {
                                    return `Display name updated`;
                                } else {
                                    return false;
                                }
                            }

                            return (
                                <Stack
                                    direction='row'
                                    alignItems="center"
                                    justifyContent="space-between"
                                >
                                    <Typography variant="body1"
                                        sx={{
                                            width: '45%'
                                        }}
                                    >
                                        {file.fileName}
                                    </Typography>

                                    <Box
                                        sx={{
                                            width: '45%'
                                        }}
                                    >
                                        <TextField
                                            value={currentValue}
                                            onBlur={(e) => {
                                                if (!e.target.value) {
                                                    setFormState((prev) => {
                                                        const newState = [...prev];
                                                        newState[idx].currentValue = file.defaultValue;
                                                        return newState;
                                                    })
                                                }
                                            }}
                                            onChange={(e) => {
                                                setFormState((prev) => {
                                                    const newState = [...prev];
                                                    newState[idx].currentValue = e.target.value;
                                                    return newState;
                                                })
                                            }}
                                            required
                                            label="Display name"
                                            fullWidth
                                            size='small'
                                            margin="normal"
                                            error={!currentValue}
                                            helperText={helperText()}
                                            FormHelperTextProps={{
                                                sx: {
                                                    color: hasChanged ? 'success.main' : 'default',
                                                },
                                            }}
                                            sx={{
                                                '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: !currentValue ? 'error.main' : hasChanged ? 'success.main' : 'default',
                                                },
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: !currentValue ? 'error.main' : hasChanged ? 'success.main' : 'default',
                                                },
                                                '& .MuiFormLabel-root': {
                                                    color: !currentValue ? 'error.main' : hasChanged ? 'success.main' : 'default',
                                                },
                                            }}
                                        />
                                    </Box>
                                    <Box 
                                    sx={{
                                        width: '10%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}>
                                        {!isDemandOrCoverLetter(file.detectedType) && (
                                            <Checkbox
                                            checked={formState[idx].hasDividerPage}
                                            onChange={(e) => {
                                                setFormState((prev) => {
                                                    const newState = [...prev];
                                                    newState[idx].hasDividerPage = e.target.checked;
                                                    return newState;
                                                });
                                            }}
                                        />
                                        )}
                                    </Box>
                                </Stack>
                            )
                        })}
                    </Grid>
                </Grid>
            </DialogContent>
            
            <DialogActions>
                <Button
                    color="secondary"
                    onClick={() => handleClose()}
                >
                    Cancel
                </Button>{' '}
                <Button
                    disabled={!anyChange || anyErrors}
                    variant={'contained'}
                    color={'secondary'}
                    onClick={handleSave}
                >
                    Save
                </Button>
            </DialogActions>
        </>

    );
}

export default EditDocumentNames;