import { reduce, isEqual, isObject } from 'lodash';
import { setToast } from '../../redux/slices/globalToastSlice';
import { setPdfUrlPollingLoadingSkeleton } from '../../services/pdf/customSlice';

export const getDifferencesFromNestedObjects = (obj1, obj2) => {
  const differenceDictionary = reduce(obj1, (result, value, key) => {
    if (!isEqual(value, obj2[key])) {
      result[key] = isObject(value) && isObject(obj2[key])
        ? getDifferencesFromNestedObjects(value, obj2[key]) // Recurse for nested objects
        : value
    }
    return result;
  }, {});
  return differenceDictionary;
}

export const handleDemandEditingSave = async ({
  data = {},
  callbacks = {},
  setIsSaving,
  dispatch,
  saveDocumentData,
  triggerGetDocumentData,
  documentId
}) => {
  setIsSaving(true);

  try {
    const documentSaveSuccess = await saveDocumentData({ data }).unwrap();
    triggerGetDocumentData({ documentId, pollPdfStatus: true });
    if (documentSaveSuccess) { dispatch(setToast({ message: 'Demand updated successfully', severity: 'success', isOpen: true, })); }
    callbacks.handleClose();
    setIsSaving(false);
  } catch (error) {
    dispatch(setToast({ message: `Error updating demand: ${error.message}`, severity: 'error', isOpen: true, }));
    setIsSaving(false);
    callbacks.handleClose();
  }
};

export const handleDemandCreationSave = async ({
  data = {},
  dispatch,
  saveDocumentData,
  triggerGetDocumentData,
  callbacks = {},
  setIsSaving,
  sendForAiProcessing
}) => {
  setIsSaving(true);
  try {
    const documentSaveSuccess = await saveDocumentData({ data }).unwrap();
    const { documentId } = documentSaveSuccess;
    dispatch(setPdfUrlPollingLoadingSkeleton({ [documentId]: true }));
    callbacks.uploadFiles(documentId);
    await new Promise((resolve) => setTimeout(resolve, 2000));
    callbacks.handleSaveSuccessCallback(documentId);
    setIsSaving(false);
  } catch (error) {
    dispatch(setToast({
      message: `Error creating demand: ${error.message}`,
      severity: 'error',
      isOpen: true,
    }));
    setIsSaving(false);
    callbacks.handleSaveSuccessCallback(null);
  }
}

export const formatPageNumbers = (pageNumbers) => {
  const formattedList = [];
  let consecutiveRangeStart;
  let previousNumber;
  pageNumbers.forEach((pageNumber) => {
    if (consecutiveRangeStart && pageNumber === previousNumber + 1) {
      formattedList[formattedList.length - 1] = `${consecutiveRangeStart}-${pageNumber}`;
    } else {
      formattedList.push(pageNumber);
      consecutiveRangeStart = pageNumber;
    }
    previousNumber = pageNumber;
  });
  return formattedList.join(', ');
};

export const formatDateRange = (dates) => {
  //todo sort
  return `${dates[0]} - ${dates[dates.length - 1]}`;
}