import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import { aiNarrativeGenerationApi } from './aiNarrativeGenerationApi'

const originalGenerationState = {
    selectedField: "",
    aiGenerationPrompt: "",
    aiGenerationData: "",
    aiDataSourceConfig: "",
    tabValue: 0,
    fieldsWithGeneratedNarrative: {},
    aiReferencesForField: {},
}

const aiNarrativeGenerationSlice = createSlice({
    name: 'aiNarrativeGeneration',
    initialState: originalGenerationState,
    reducers: {
        setSelectedField: (state, action) => {state.selectedField = action.payload },
        setAiGenerationPrompt: (state, action) => {state.aiGenerationPrompt = action.payload },
        setAiGenerationData: (state, action) => {state.aiGenerationData = action.payload },
        setAiDataSourceConfig: (state, action) => {state.aiDataSourceConfig = action.payload },
        setTabValue: (state, action) => { state.tabValue = action.payload },
        setFieldsWithGeneratedNarrative: (state, action) => { 
            state.fieldsWithGeneratedNarrative = { ...state.fieldsWithGeneratedNarrative, ...action.payload };
        },
        setAIReferencesForField: (state, action) => {
            state.aiReferencesForField = { ...state.aiReferencesForField, ...action.payload };
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            isAnyOf(aiNarrativeGenerationApi.endpoints.generateNarrativeAdmin.matchFulfilled),
            (state, action) => {
                if (action.payload) {
                    state.tabValue = 2; // switch to results tab
                }
            }
        );
        builder.addMatcher(
            isAnyOf(aiNarrativeGenerationApi.endpoints.loadAiData.matchFulfilled),
            (state, action) => {
                if (action.payload) {
                    state.aiGenerationData = JSON.stringify(action.payload);
                    if (state.tabValue === 2) {
                        state.tabValue = 0; // switch to data tab
                    }
                }
            }
        );
        builder.addMatcher(
            isAnyOf(aiNarrativeGenerationApi.endpoints.loadAiData.matchRejected),
            (state, action) => {
                state.aiGenerationData = "";
            }
        );
        builder.addMatcher(
            isAnyOf(aiNarrativeGenerationApi.endpoints.loadAiData.matchPending),
            (state, action) => {
                state.aiGenerationData = "";
            }
        );
    }
})

export const { setSelectedField, setAiGenerationPrompt, setAiGenerationData, setAiDataSourceConfig, setTabValue, setFieldsWithGeneratedNarrative, setAIReferencesForField } =
  aiNarrativeGenerationSlice.actions;

export default aiNarrativeGenerationSlice.reducer;