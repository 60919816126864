import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Stack, Typography, Box, Tooltip, Paper, IconButton } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DataGridWithExpandedDetails from '../../CommonComponents/DataGrid/DataGridWithExpandedDetails';
import AiReferencesPageDetailPanel from './AiReferencesPageDetailPanel';
import Title from '../../CommonComponents/Title';
import { formatPageNumbers } from '../DemandForms/Utilities';
import { copyToClipboard } from '../../common';
import { setToast } from '../../redux/slices/globalToastSlice';
import { theme } from '../../Theme';

const initialState = {
  sorting: {
    sortModel: [{ field: 'page', sort: 'asc' }],
  },
};

const AiReferencesTreatmentDetailPanel = ({ treatments }) => {
  const rows = treatments.map((treatment, index) => ({ ...treatment, id: index + 1 }));
  console.log(rows); //TODO: remove
  const columns = [
    {
      field: 'generated_page_number_list',
      headerName: 'Pages',
      width: 100,
      editable: false,
      filterable: true,
      valueGetter: (params) => params.row.generated_page_number_list[0],
      renderCell: ({ row }) => {
        return <Typography variant="tableP1">{formatPageNumbers(row.generated_page_number_list)}</Typography>;
      },
    },
    {
      field: 'treatment_date',
      headerName: 'Date',
      width: 150,
      editable: false,
      filterable: true,
      renderCell: ({ row }) => {
        return <Typography variant="tableP1">{row.treatment_date.value}</Typography>;
      },
    },
    {
      field: 'summary',
      headerName: 'Summary',
      width: 150,
      editable: false,
      filterable: false,
      sortable: false,
      pinnable: false,
      resizable: false,
      renderCell: ({ row }) => {
        return (
          <Tooltip
            title={<SummaryTooltip treatmentDate={row.treatment_date.value} attributes={row} />}
            placement="right-start"
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [-6, -16],
                    },
                  },
                ],
              },
            }}
            componentsProps={{
              tooltip: {
                sx: {
                  fontWeight: 450,
                  bgcolor: 'transparent',
                },
              },
            }}
          >
            <Stack direction="row" alignItems="center" spacing={1}>
              <InfoOutlinedIcon />
              <Typography variant="tableP1">View</Typography>
            </Stack>
          </Tooltip>
        );
      },
    },
  ];

  const getDetailPanelContent = useCallback(
    ({ row }) => <AiReferencesPageDetailPanel pages={row.generated_page_number_list} />,
    []
  );

  return (
    <DataGridWithExpandedDetails
      rows={rows}
      columns={columns}
      getDetailPanelContent={getDetailPanelContent}
      isDetailPanel
      initialState={initialState}
    />
  );
};

const SummaryTooltip = ({ treatmentDate, attributes }) => {
  const dispatch = useDispatch();

  return (
    <Paper
      elevation={8}
      sx={{
        px: 2,
        py: 1,
        width: '600px',
      }}
    >
      <Stack direction={'row'} justifyContent="space-between" alignItems="center">
        <Title>Summary: {treatmentDate}</Title>
        <IconButton
          size="small"
          onClick={() =>
            copyToClipboard(attributes, () =>
              dispatch(setToast({ isOpen: true, message: 'Copied to clipboard!', severity: 'success' }))
            )
          }
        >
          <ContentCopyIcon />
        </IconButton>
      </Stack>
      <Stack spacing={1} sx={{ mt: 1 }}>
        {Object.entries(attributes).forEach(([key, value]) => {
          return (
            <Box key={key}>
              <Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>
                {key}
              </Typography>
              <Typography variant="body2">{value}</Typography>
            </Box>
          );
        })}
      </Stack>
    </Paper>
  );
};

export default AiReferencesTreatmentDetailPanel;
